import React from 'react';
import styled from "styled-components";
import { useConfetti } from 'use-confetti-svg';

const elements = {
  fadeInWithDelayContainer: styled.div`
    opacity: 0;
    animation-name: fadeInAnimation;
    animation-fill-mode: forwards;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    

    text-align: center;

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  NEJ: styled.h1`
  font-size: 10vw; /* Increase this value to make the text larger */
  color: white;
  margin-top: 0px;
  `,
  christian: styled.img`
  max-height: 300px;`
};

const App = () => {
  const [size, setSize] = React.useState("10,20,30");
  const [weight, setWeight] = React.useState("2,10,2");
  const [duration, setDuration] = React.useState("3000");
  const [fadeOut, setFadeOut] = React.useState(true);
  const [fadeOutDuration, setFadeOutDuration] = React.useState("2000");
  const [particleCount, setParticleCount] = React.useState("50");
  const [speed, setSpeed] = React.useState("50");
  const [rotate, setRotate] = React.useState(true);

  const { runAnimation } = useConfetti({
    images: size.split(",").map((size, index) => {
      const weights = weight.split(",");
      return {
        src: `${process.env.PUBLIC_URL}/christian.png`,
        size: parseInt(size.trim()) ?? 1,
        weight:
          parseInt(weights[index]?.trim()) ??
          parseInt(weights[0]?.trim()) ??
          undefined
      };
    }),
    duration: parseInt(duration),
    fadeOut: fadeOut ? parseInt(fadeOutDuration) : false,
    particleCount: parseInt(particleCount),
    speed: parseInt(speed),
    rotate
  });

  React.useEffect(() => {
    const animation = runAnimation();

    // Om runAnimation returnerar en funktion, använd den som cleanup
    return typeof animation === 'function' ? animation : undefined;
  }, []);


  return (
    <elements.fadeInWithDelayContainer>
      <elements.christian src={`${process.env.PUBLIC_URL}/christian.png`} alt="Christian" />
      <elements.NEJ>EVENTUELLT</elements.NEJ>
    </elements.fadeInWithDelayContainer >
  );
};

export default App;
